<template>
    <section class="custom-scroll overflow-auto" style="height:100vh;">
        <div class="row mx-0 bg-leeche justify-content-between align-items-center px-5" style="height:80px;border-radius:0 0 0 20px;">
            <img class="ml-3" src="/img/auth/logo_blanco.svg" height="33px" style="max-width:100%;" />
            <p class="cr-pointer text-white f-600" @click="cerrarSesion()">Cerrar sesión</p>
        </div>

        <div class="row mx-0">
            <div class="col d-middle-center">
                <div class="text-center">
                    <img src="img/login_files/registrado.svg" alt="" width="550px" class="obj-cover mt-5" />
                    <div class="row mx-0 my-3 f-600 f-20 justify-center">
                        ¡Genial!
                    </div>
                    <div class="row mx-0 f-18 my-3 justify-center">
                        Ahora haces parte de la comunidad
                    </div>
                    <div class="row mx-0 f-18 my-3 justify-center">
                        Recuerda completar tus datos de registro para iniciar tu <br /> proceso de postulación como {{$config.vendedor}}.
                    </div>

                    <div class="row mx-0 mt-5 justify-center d-flex justify-content-around">
                        <div type="button" class="bg-leeche text-white px-5 cr-pointer py-2 br-12" name="button" @click="$router.push({name: 'tendero.carrito'})">
                            Ir al catálogo
                        </div>
                        <div class="br-12 border py-2 px-5 border text-general bg-light-f5 cr-pointer" @click="$router.push({name: 'tendero.conf.datosTienda'})">
                            Completar registro
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    layout: 'postulacion',
    middleware: ['auth'],
    methods: {
        async cerrarSesion(){
            await this.$store.dispatch('auth/intentarCerrarSesion')
            this.$router.push({name:'login.form.admin'})

        }
    }

}
</script>
